import React from "react";
import PrismicRichText from "../PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import CustomCarousel from "../Carousel";
import Link from "../Link";
import isValidLink from "./../../helpers/isValidLink";
import { get } from "lodash";

const Hero = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const bgImage = isMobile
    ? props.mobileBackgroundImage
    : props.backgroundImage;

  const backgroundColor = {
    background: props.backgroundColor,
  };

  const ctaAlignment = props.ctaAlignment;

  const backgroundVideo = props.backgroundVideo;

  const renderButtons = (buttons) => {
    return buttons.map((el, i) => {
      const ctaText = el.cta_text;
      const ctaLink = el.cta_link;
      if (isValidLink(ctaLink, ctaText)) {
        return (
          <Link
            to={"/" + get(el, "cta_link.uid")}
            url={get(el, "cta_link.url")}
            target={get(el, "cta_link.target")}
            className="button outlined"
            key={i}
          >
            {ctaText[0].text}
          </Link>
        );
      }
      return false;
    });
  };

  return (
    <section className="hero-section gray-headlines-and-copy">
      <div className="extra-margin"></div>
      <div className="wrapper">
        {!isMobile &&
          !backgroundVideo &&
          !bgImage &&
          props.gallery.length >= 2 && (
            <div className="product-slides-container">
              {props.gallery.map((el, i) => (
                <img src={el.product_photo.url} alt="wonder products" />
              ))}
            </div>
          )}

        {!isMobile && !backgroundVideo && props.backgroundImage && (
          <div className="background-image">
            <img src={bgImage} alt="Hero Image" />
          </div>
        )}
        {isMobile &&
          props.gallery.length <= 1 &&
          !backgroundVideo &&
          bgImage && (
            <div className="background-image">
              <img src={bgImage} alt="Hero Image" />
            </div>
          )}

        {backgroundVideo && (
          <div className="background-video">
            <video autoPlay playsInline muted loop>
              <source src={backgroundVideo} type="video/mp4" />
            </video>
          </div>
        )}

        <div className="text-content">
          <PrismicRichText render={props.richContent} />
          {isMobile &&
            props.gallery &&
            props.gallery.length > 1 &&
            !backgroundVideo && (
              <div className="hero-carousel hide-thumbs">
                <CustomCarousel items={props.gallery} />
              </div>
            )}

          <PrismicRichText render={props.richContent2} />
          {props.buttons && props.buttons[0].cta_text[0] && (
            <div
              className={`buttons ${
                ctaAlignment === "center-align" && "center-align"
              } ${ctaAlignment === "right-align" && "right-align"}`}
            >
              {renderButtons(props.buttons)}
            </div>
          )}
        </div>
      </div>
      <div className="overlay"></div>
      <div className="background-color" style={backgroundColor}></div>
    </section>
  );
};

export default Hero;
